export default {
  APP_NAME: process.env.VUE_APP_NAME,
  API_HOST: process.env.VUE_APP_API_HOST,
  SECRET_KEY: process.env.VUE_APP_SECRET_KEY,
  API_VERSION: process.env.VUE_APP_API_VERSION,
  API_STANDARDS_TREE: process.env.VUE_APP_API_STANDARDS_TREE,
  VUEX_STORE_KEY: `${process.env.VUE_APP_NAME}-store-${process.env.NODE_ENV}`,
  APP_APPID: process.env.VUE_APP_APPID,
  VUEX_STORE_STORAGE: window.localStorage,
};
