import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import createPersistedState from 'vuex-persistedstate';

import app from './modules/app';
import user from './modules/user';
import address from './modules/address';
import order from './modules/order';

import appConfig from '../app.config';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    address,
    order,
  },
  plugins: [
    createPersistedState({
      key: appConfig.VUEX_STORE_KEY,
      storage: appConfig.VUEX_STORE_STORAGE,
      reducer: (persistedState) => {
        const stateFilter = _.cloneDeep(persistedState);
        [
          //
        ].forEach(key => _.unset(stateFilter, key));
        return stateFilter;
      },
    }),
  ],
});
