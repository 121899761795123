import _ from 'lodash';
import { Api } from '@/api';

const state = {
  orderStatistics: {},
  orderList: {},
  orderDetail: {},
};
const getters = {
  unpaidOrderCount: state => _.get(state, 'orderStatistics.1', 0),
  unpickupOrderCount: state => _.get(state, 'orderStatistics.2', 0),
  issueOrderCount: state => _.get(state, 'orderStatistics.issue', 0),
  unpaidAdditionalFeeCount: state => _.get(state, 'orderStatistics.additional', 0),
};

const actions = {
  orderStatistics({ commit }) {
    Api.order.statistics().then(({ data: res }) => {
      commit('receiveOrderStatistics', res);
    });
  },
  fetchOrderList({ commit }, code) {
    const orderApi = code !== undefined ? Api.order.search(code) : Api.order.get();
    return orderApi.then(({ data: response }) => {
      const arr = {
        all: [],
        no_pay: [],
        payed: [],
        completed: [],
        closed: [],
        transport: [],
      };
      arr.all = response.data;
      response.data.forEach((item) => {
        if (item.status === 1) {
          arr.no_pay.push(item);
        } else if (item.status === 2) {
          arr.payed.push(item);
        } else if (item.status === 4 || item.status === 6) {
          arr.closed.push(item);
        } else if (item.status === 5) {
          arr.completed.push(item);
        } else if (item.status === 3) {
          arr.transport.push(item);
        }
      });
      commit('getOrderList', arr);
    });
  },
  fetchOrderDetail({ commit }, id) {
    return Api.order.check(id).then(({ data: response }) => {
      commit('getOrderDetail', response.data);
    });
  },
  deleteOrder({ commit, state }, id) {
    return Api.order.remove(id).then(() => {
      const arr = state.orderList;
      let index = null;
      Object.keys(arr).forEach((item) => {
        index = arr[item].findIndex(data => data.id === id);
        if (index) {
          arr[item].splice(index, 1);
          commit('removeOrder', arr);
        }
      });
    });
  },
};
/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveOrderStatistics(s, data) {
    s.orderStatistics = data;
  },
  getOrderList(s, data) {
    s.orderList = data;
  },
  getOrderDetail(s, data) {
    s.orderDetail = data;
  },
  removeOrder(s, data) {
    s.orderList = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
