/* eslint-disable */
export default {
  common: {
    spec_ex: '例：尺码，容量，型号',
    logining: '正在登陆',
    optional: '可选',
    confirmed: '确定',
    cancel: '取消',
    default: '默认',
    delete: '删除',
    deleted: '已删除',
    saving: '正在保存',
    saved: '已保存',
    loading: '加载中',
    submit: '提交',
    edit: '编辑',
    save: '保存',
    other: '其他',
    click_for_select_field: '点击选择{field}',
    input_field_placeholder: '请填写{field}',
    not_more: '没有更多了',
    wx_not_support_function: '您的微信不支持此功能',
    input_search_keyword: '请输入搜索关键字',
    tips: '提示',
    bind_email_tips: '请先绑定邮箱',
    file_size_max: '文件大小不能超过{size}',
    search: '搜索',
    agree: '同意',
    disagree: '不同意'
  },
  app: {
    home: '首页',
    create_order: '寄件',
    orders: '查件',
    order_manage: '订单管理',
    postoffices: '门店',
    address_manage: '地址薄',
    notifications: '消息通知',
    cs: '联系客服',
    wordOrder: '工单',
    me: '我的',
  },
  address: {
    sender_address: '寄件人',
    receiver_address: '收件人地址',
    create: '新增地址',
    area: '地区',
    zipcode: '邮政编码',
    card_no: '身份证号码',
    card_file: '身份证正面照片',
    opposite_file: '身份证背面照片',
    placeholders: {
      area: '点击选择城市',
      card_no: '请填入身份证号码',
      zipcode: '请输入邮政编码',
    },
    please_upload_image: '请上传图片',
    parse_address_default_placeholder: '在这里粘贴地址信息，自动拆分姓名、身份证、电话和地址（暂仅支持识别中国大陆地区）',
    parse_address_focus_placeholder: '粘贴或输入整段信息，点击"识别"自动拆分地址信息。例如：小智 136****0000 xx省xx市xx区某某街道某大厦001号（暂仅支持识别中国大陆地区）',
    parsed_tips: '如信息不准确，可以继续编辑修改再提交哦',
    parse_address: '识别',
    parse_clear: '清空',
    phone_area_code: '区号',
    other_phone_area_code: '其他（请输入带区号的电话）',
    delete_comfirm: '确定要删除这个地址吗？',
    validate_messages: {
      name: '真实姓名',
      address: '请输入详细地址',
      phone: '请输入电话号码',
      card_file: '请上传身份证正面照片',
      opposite_file: '请上传身份证背面照片',
      district: '请选择地区',
      zipcode: '请输入邮政编码',
      card_no: '请输入身份证号吗',
      phone_error: '请输入正确的电话号码',
      realname_error: '请输入真实姓名',
      address_detail: '请输入详细地址',
    },
    share_wx_address: '共享微信地址',
    supplement_card: '请补充身份证信息',
  },
  sender_address: {
    name: '姓名',
    phone: '电话',
    isDefault: '设为默认寄件人',
    placeholders: {
      name: '寄件人姓名',
      phone: '寄件人电话号码',
    },
  },
  receiver_address: {
    name: '姓名',
    phone: '电话',
    address_detail: '详细地址',
    isDefault: '设为默认收件人',
    placeholders: {
      name: '收件人姓名与身份证必须一致',
      phone: '收件人电话号码',
      address_detail: '街道门牌、楼层房间号等信息',
    },
  },
  order: {
    copy_tip: '请复制到浏览器下载发票文件',
    invoice: '生成发票',
    my_order: '我的运单',
    base_info: '基本信息',
    product_info: '商品信息',
    title: '在线下单',
    reference_id: '订单号码',
    parcel_code: '邮包号码',
    post_office_code: '门店编码',
    post_office: '寄件门店',
    sender: '寄件人',
    receiver: '收件人',
    upload_small_ticket: '上传小票',
    small_ticket: '小票',
    input_product_info: '添加商品信息',
    product_limit: '商品种类不能超过{num}个',
    placeholders: {
      parcel_code: '请输入袋编号或扫描条码',
      parcel_code_or_post_office_code: '请输入或扫描条码/二维码',
    },
    rule_messages: {
      parcel_code_required: '请填写邮包号码',
      parcel_model_id_required: '请选择邮包规格',
      sender_address_id_required: '请选择寄件人',
      receiver_address_id_required: '请选择收件人',
      products_required: '请上报商品信息',
      ticket_required: '请上传小票',
    },
    price: '价格',
    parcel_price: '邮包价格',
    tax: '税金',
    additional_fee: '附加费',
    additional_fee_unit: '{num}笔',
    additional_fee_unpaid_tips: '您有{num}笔附加费尚未支付，请尽快支付。',
    additional_fee_unpaid_order_tips: '您有{num}笔订单尚未支付附加费，请尽快支付。',
    ordered: '下单成功',
    view_tracking: '查看物流',
    tracking_info: '物流信息',
    cancel: '取消',
    cancel_comfirm: '您要取消这个运单吗？',
    order_canceled: '运单已取消',
    status: '状态',
    statuses: {
      all: '所有订单',
      unpaid: '待支付订单',
      unpickup: '待揽收订单',
      pickup: '已揽收订单',
      tracking: '运输中订单',
      completion: '已完成订单',
      close: '已取消订单',
      issue: '问题订单',
      additional: '待支付附加费订单'
    },
    input_parcel_code_to_search: '输入邮包号码进行搜索',
    orgin_price: '原价',
    discounted: '已优惠',
    tracking_no: '运单号',
    created_at: '下单时间',
    read_and_agree: '阅读并同意',
    terms: '《电子运单契约条款》',
    send_tip: '包裹寄出前，收/寄方需清楚明白有义务配合海关申报要求，包括但不限于提供身份证号码及身份证图片',
    type: '寄件方式',
    types: {
      1: '门店寄件',
      2: '上門取件',
    },
    parcel_model: '规格',
    payment_success_tips1: '请把邮包交给店员并出示本页面条形码揽件',
    payment_success_tips2: "邮包号码：{code}\n请把邮包号码写在邮包上，然后创建上门取件单",
    product_limit_tips: '商品限制提示',
    declare_multiple_products_cannot_exceed: '申报多件商品不能超过CNY¥{price}元',
    declare_single_product_cannot_exceed: '申报单件商品不能超过CNY¥{price}元',
    tax_free_limit_exceed: '已超单票免税额{currency}{price}',
    declare_shoe_cannot_exceed: '申报鞋类不能超过1双',
    target_district: '目的地',
    parcel_type: '包裹类型',
    parcel_type_spx: '邮包',
    parcel_type_doc: '文件',
    long: '长度',
    width: '宽度',
    height: '高度',
    weight: '重量',
    prev_step: '上一步',
    next_step: '下一步',
    confirm_order: '确认下单',
    prices: {
      base: '基本运费',
      peak_season: '旺季附加费',
      fuel_surcharge: '燃油附加费',
      disposal: '排仓费',
      weight_overload: '超重附加费',
      size_overload: '超长附加费',
      remote: '偏远附加费',
      residential: '住宅附加费',
      tax: '税金',
    },
    step_0: '报价',
    step_1: '填资料',
    step_2: '下单',
    expected_post_date: '预计投递日期',
    expected_post_date_popup_title: '选择预计预计投递日期',
    price_detail: '价格详细',
    load_cache_confirm: '是否读取您上次未完成的订单数据',
    district_filter: '输入地区关键字进行筛选',
  },
  pickup_order: {
    id: '取件单号',
    pickup: '上门取件',
    pickup_order: '上门取件单',
    order: '取件订单',
    order_list: '取件订单列表',
    no_orders_tips: '您没有待揽收订单',
    go_to_order: '去下单',
    pickup_address: '取件地址',
    contact: '联系人',
    phone: '电话',
    select_pickup_address: '请选择取件地址',
    address_disabled_text: '非香港地区或没有详细地址不可用',
    select_order: '寄送订单',
    select_time: '期望上门时间',
    submit: '提交取件单',
    price: '上门费用',
    free: '免费',
    charges: '上门收费标准：{num}单内，每次{price}。10单或以上，免收上门费。',
    contai_order: '包含订单',
    status: '状态',
    statuses: {
      0: '未支付',
      1: '已支付',
      2: '取件中',
      3: '已取件',
      9: '已取消',
    },
    cancel_comfirm: '您确定要取消这个取件单吗？',
    order_canceled: '取件单已取消',
    pickup_date: '取件日期',
    pickup_date_placeholder: '请选择上门取件日期',
    pickup_time: '取件时间',
    pickup_time_placeholder: '请选择上门取件时间段',
    remark: '备注',
    picking_tip: '如需取消请联系智邮易客服',
  },
  order_status: {
    order_closed: '已关闭',
    order_created: '待支付',
    order_paid: '已付款',
    order_pickup: '已揽件',
    order_tracking: '运输中',
    order_completion: '已完成',
    order_canceled: '已取消',
    order_timeout: '超时取消',
    undefined: '未定义',
  },
  order_detail: {
    title: '运单详情',
    additional_fee: {
      price: '金额',
      reason: '原因',
      payment_status: '支付状态',
    },
  },
  parcel_model: {
    price: '价格',
    max_weight: '最大重量',
  },
  order_list: {
    title: '我的运单',
  },
  post_offices: {
    title: '门店列表',
  },
  parcel: {
    name: '型号',
    max_weight: '最大重量',
  },
  product: {
    name: '商品名称',
    en_name: '商品英文名称',
    quantity: '数量',
    category: '商品分类',
    gross_weight: '重量',
    unit: '单位',
    price: '单价',
    tax: '税金',
    currency: '货币',
    post_tax_num: '行邮税号',
    origin_country: '原产地',
    brand: '品牌',
    spec: '规格',
    origin_country_info: '请选择原产地',
    rule_messages: {
      name_required: '请填写商品名称',
      en_name_required: '请填写商品英文名称',
      brand_required: '请填写商品品牌',
      spec_required: '请填写商品规格',
      category_required: '请选择商品分类',
      price_required: '请填写商品价格',
    },
    confirm_delete: '要删除这个商品吗？',
    placeholder: {
      price: '申报价格会以单价乘以数量',
    },
    tips: '请务必如实申报商品资料！',
    tips2: '如有商品购物小票，请直接放置于包裹内！',
  },
  payment: {
    wechatpay: '微信支付',
    payment_success: '支付成功',
    payment_fail: '支付失败',
    payment_cancel: '已取消支付',
    status: {
      0: '未支付',
      1: '已支付',
    },
    refunding: '退款中',
    refunded: '已退款',
  },
  coupon: {
    coupon: '优惠券',
    no_use_coupon: '不使用优惠券',
    exchanged: '已兑换优惠券',
    use_amount_condition: '订单满{price}可用',
    no_condition: '无使用门槛',
    new_user_only: '新用户专用',
    disable_reasons: {
      expired: '已过期',
      amount_condition: '运单满{price}可用',
      district: '该地区不再优惠券适用范围内',
    },
  },
  notification: {
    all_mark_readed: '全部标记为已读',
    mark_unreaded: '标记未读',
  },
  user: {
    title: '会员中心',
    logout: '退出登陆',
    share: '推荐我们',
    orders: '运单',
    cs_tel: '客服电话',
    change_language: '切换语言',
  },
  userinfo: {
    tips: '绑定邮箱/手机号，设定密码可使您的账号在E-Smartship APP中登录',
    title: '会员信息',
    bind_email: '绑定邮箱',
    update_password: '修改密码',
    email: '邮箱',
    email_verify_code: '邮箱验证码',
    send_verify_code: '发送验证码',
    resend: '重新发送',
    old_password: '原密码',
    new_password: '新密码',
    new_password_confirmation: '确认新密码',
    placeholder: {
      email: '请填写您的E-mail',
      email_verify_code: '请输入邮箱验证码',
      old_password: '请输入原密码',
      new_password: '请输入新密码',
      new_password_confirmation: '请再次输入新密码',
    },
    message: {
      email_validate: '请输入正确的邮箱格式',
    },
  },
  workOrder: {
    list: '问题跟踪',
    create: '问题反饋',
    detail: '工单详細',
    questionId: {
      300: '报关查询',
      301: '包裹丢失、破损',
      302: '轨迹查询',
      303: '派送问题',
      304: '其他',
      308: '虚假签收',
    },
    types: {
      1: '运单',
      2: '其他',
    },
    type: '类型',
    status: '工单狀態',
    title: '标题',
    question: '问题類型',
    description: '描述',
    email: '联系邮箱',
    phone: '联系电话',
    billNumber: '邮包号码',
    attachment: '附件',
    upload_attachment: '上传附件',
    submit: '提交工单',
    placeholder: {
      question: '点击选择问题類型',
      billNumber: '点击选择邮包',
      phone: '请输入联系电话',
      title: '请输入标题',
      description: '请填写工单描述',
    },
    validate_error: '请正确填写工单',
    statuses: {
      A0: '待审核',
      A1: '待受理',
      A2: '受理中',
      A3: '受理完成',
      A4: '已关闭',
    },
    refference_no: '工单号',
    info: '工单信息',
    messages: '消息',
    create_success: '您的问题已反饋',
    please_input_message: '请输入內容',
    send: '发送',
    sending: '发送中',
    view_attachments: '查看附件',
  },
  area: {
    China: '中国大陆',
    HongKong: '香港',
    Macau: '澳门',
    TaiWan: '台湾',
    US: '美国',
    France: '法国',
    UK: '英国',
    Germany: '德国',
    Malaysia: '马来西亚',
    Australia: '澳洲',
    NewZealand: '纽西兰',
    Singapore: '新加坡',
    Thailand: '泰国',
    Japan: '日本',
    Korea: '韩国',
    VietNam: '越南',
    Cambodia: '柬埔寨',
  },
  errors: {
    parcel_disabled: '包裹已使用',
    address_not_exists: '地址不存在',
    parameter_error: '参数错误',
    operation_failed: '操作失败',
    order_not_exist: '订单不存在',
    invalid_parcel_code: '无效的邮包号吗',
    order_has_been_paid: '订单已支付',
    order_has_been_canceled: '订单已被取消',
    coupon_use_limit: '优惠码超过限制',
    coupon_expired: '优惠码过期',
  },
  DDP: {
    tips: '请在「关邮e通」上完成：',
    step1: '1) 规范申报',
    step2: '2) 自行交税',
    wechat: '微信入口',
    web: '网站入口',
  },
}
