import Vue from 'vue';
import appConfig from './app.config';

const { API_HOST } = appConfig;

/* eslint-disable no-param-reassign */

const Api = {
  app: {
    config() {
      return Vue.axios.get(`${API_HOST}/config`);
    },
    jsSdk(params = {}) {
      if (window && !params.url) {
        params.url = sessionStorage.getItem('url');
      }
      return Vue.axios.get(`${API_HOST}/js-sdk-config`, { params });
    },
    // 获取地区列表
    district() {
      return Vue.axios.get(`${API_HOST}/district`);
    },
    districtRoot() {
      return Vue.axios.get(`${API_HOST}/district/root`);
    },
    districtFullPathName() {
      return Vue.axios.get(`${API_HOST}/district/full-path-name`);
    },
    // 获取邮局列表
    postOffice(params = {}) {
      return Vue.axios.get(`${API_HOST}/post-office`, { params });
    },
    // 获取商品分类
    productCategories() {
      return Vue.axios.get(`${API_HOST}/product-categories`);
    },
    notifications(params = {}) {
      return Vue.axios.get(`${API_HOST}/system-information`, { params });
    },
    terms() {
      return Vue.axios.get(`${API_HOST}/delivery-terms`);
    },
    userAgreement() {
      return Vue.axios.get(`${API_HOST}/user-agreement`);
    },
    cs() {
      return Vue.axios.get(`${API_HOST}/cs`);
    },
    parcelModel() {
      return Vue.axios.get(`${API_HOST}/parcel-model`);
    },
    originCountry() {
      return Vue.axios.get(`${API_HOST}/origin-country`);
    },
    parseAddress(params = {}) {
      return Vue.axios.get(`${API_HOST}/parse-address`, { params });
    },
  },
  user: {
    identity() {
      return Vue.axios.get(`${API_HOST}/identity`);
    },
    save(params) {
      return Vue.axios.post(`${API_HOST}/user-info`, params);
    },
    authorization(code) {
      return Vue.axios.get(`${API_HOST}/wechat-authorization?code=${code}`);
    },
    sendEmail(params) {
      return Vue.axios.post(`${API_HOST}/send-email`, params);
    },
  },
  postOffice: {
    get(id) {
      return Vue.axios.get(`${API_HOST}/post-office/${id}`);
    },
  },
  address: {
    sender: {
      save(params) {
        return Vue.axios.post(`${API_HOST}/sender-address`, params);
      },
      get() {
        return Vue.axios.get(`${API_HOST}/sender-address`);
      },
      remove(id) {
        return Vue.axios.delete(`${API_HOST}/sender-address/${id}`);
      },
      default(params) {
        return Vue.axios.post(`${API_HOST}/set-default-sender-address`, params);
      },
    },
    receiver: {
      save(params) {
        return Vue.axios.post(`${API_HOST}/receiver-address`, params,
          { headers: { 'Content-Type': 'multipart/form-data' } });
      },
      get(params = {}) {
        return Vue.axios.get(`${API_HOST}/receiver-address`, { params });
      },
      remove(id) {
        return Vue.axios.delete(`${API_HOST}/receiver-address/${id}`);
      },
      default(params) {
        return Vue.axios.post(`${API_HOST}/set-default-receiver-address`, params);
      },
      disableList(id) {
        return Vue.axios.delete(`${API_HOST}/disable-list/${id}`);
      },
    },
  },
  order: {
    statistics() {
      return Vue.axios.get(`${API_HOST}/order-statistics`);
    },
    create(params) {
      return Vue.axios.post(`${API_HOST}/order`, params);
    },
    get(params = {}) {
      return Vue.axios.get(`${API_HOST}/order`, { params });
    },
    remove(id) {
      return Vue.axios.delete(`${API_HOST}/order/${id}`);
    },
    createInvoice(id) {
      return Vue.axios.get(`${API_HOST}/order-invoice/${id}`);
    },
    search(code) {
      return Vue.axios.get(`${API_HOST}/order?code=${code}`);
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/order/${id}`);
    },
    tracking(id) {
      return Vue.axios.get(`${API_HOST}/order-tracking/${id}`);
    },
    wechatpay(id, params = {}) {
      return Vue.axios.get(`${API_HOST}/wechat-pay/${id}`, { params });
    },
    wechatpayQuery(id, params = {}) {
      return Vue.axios.get(`${API_HOST}/wechat-query/${id}`, { params });
    },
    wechatpayCancel(id, params = {}) {
      return Vue.axios.get(`${API_HOST}/wechat-cancel/${id}`, { params });
    },
    calcItemTax(params) {
      return Vue.axios.post(`${API_HOST}/calc-order-item-tax`, params);
    },
    quotedPrice(params) {
      return Vue.axios.post(`${API_HOST}/quoted-price`, params);
    },
  },
  pickupOrder: {
    create(params) {
      return Vue.axios.post(`${API_HOST}/pickup-order`, params);
    },
    get(params = {}) {
      return Vue.axios.get(`${API_HOST}/pickup-order`, { params });
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/pickup-order/${id}`);
    },
    remove(id) {
      return Vue.axios.delete(`${API_HOST}/pickup-order/${id}`);
    },
  },
  parcel_code: {
    check(id) {
      return Vue.axios.get(`${API_HOST}/check-parcel-code/${id}`);
    },
  },
  coupon: {
    check(params) {
      return Vue.axios.post(`${API_HOST}/check-coupon`, params);
    },
    list() {
      return Vue.axios.get(`${API_HOST}/coupons`);
    },
    myCoupons() {
      return Vue.axios.get(`${API_HOST}/my-coupons`);
    },
    exchange(code) {
      return Vue.axios.post(`${API_HOST}/coupon`, { code });
    },
  },
  workOrder: {
    get(params) {
      return Vue.axios.get(`${API_HOST}/work-order`, { params });
    },
    post(params) {
      return Vue.axios.post(`${API_HOST}/work-order`, params);
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/work-order/${id}`);
    },
    sendMessage(id, params) {
      return Vue.axios.put(`${API_HOST}/work-order/${id}`, params);
    },
  },
};

export default function plugin(_Vue) {
  if (plugin.installed) {
    return;
  }
  plugin.installed = true;

  Object.defineProperty(_Vue.prototype, '$api', {
    get() {
      return Api;
    },
  });
}

export {
  Api,
};
