<template>
  <div id="app">
    <div class="not-in-wechat" v-if="!wechat">
      <img src="/static/qrcode_for_gh_a71e134f1ddc_258.jpg" />
      <p class="tip">請使用微信掃描二維碼</p>
      <img src="/static/wx-search.png" />
    </div>
    <template v-else>
      <!--底欄11-->
      <router-view />
      <van-tabbar route v-show="showTabbar">
        <van-tabbar-item icon="wap-home-o" to="/" >{{ $t('app.home') }}</van-tabbar-item>
        <van-tabbar-item icon="logistics" to="/order">{{ $t('app.create_order') }}</van-tabbar-item>
        <van-tabbar-item icon="orders-o" to="/order-index" :dot="unpaidOrderCount > 0 || unpickupOrderCount > 0">
          {{ $t('app.order_manage') }}
        </van-tabbar-item>
        <van-tabbar-item icon="contact" to="/user">{{ $t('app.me') }}</van-tabbar-item>
      </van-tabbar>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import appConfig from '@/app.config';
import { showErrorMessage, Probability } from '@/helpers';

const { APP_APPID } = appConfig;

export default {
  name: 'App',
  data() {
    return {
      wechat: false,
      showOrdersPopover: false,
      orderMenus: [
        { text: this.$t('order.statuses.unpaid'), route: { name: 'orders', params: { status: 'unpaid' } } },
        { text: this.$t('order.statuses.unpickup'), route: { name: 'orders', params: { status: 'unpickup' } } },
        { text: this.$t('order.statuses.pickup'), route: { name: 'orders', params: { status: 'pickup' } } },
        { text: this.$t('order.statuses.tracking'), route: { name: 'orders', params: { status: 'tracking' } } },
        { text: this.$t('order.statuses.completion'), route: { name: 'orders', params: { status: 'completion' } } },
        { text: this.$t('order.statuses.close'), route: { name: 'orders', params: { status: 'close' } } },
        { text: this.$t('pickup_order.pickup'), route: { name: 'pickup-order' } },
        { text: this.$t('order.statuses.all'), route: { name: 'orders' } },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.isLogined) {
          this.$store.dispatch('orderStatistics');
          this.$store.dispatch('identity');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i)) {
      this.wechat = true;
      if (this.$route.query.code && !this.isLogined && this.$route.query.state === 'authorization') {
        this.$toast.loading({
          message: this.$t('common.logining'),
          forbidClick: true,
          duration: 0,
          overlay: true,
        });
        this.$store.dispatch('authorization', this.$route.query.code).then(() => {
          this.$store.dispatch('identity');
          this.$router.replace({ query: {} });
        }).catch((e) => {
          showErrorMessage(e);
        }).finally(() => {
          this.$toast.clear();
        });
      }
      if (!this.isLogined && !this.$route.query.code) {
        const url = encodeURIComponent(window.location.origin);
        const scope = 'snsapi_userinfo';
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?'
          + `appid=${APP_APPID}&redirect_uri=${url}`
          + `&response_type=code&scope=${scope}&state=authorization#wechat_redirect`;
      }
    }
    (new Probability({
      f: () => this.$store.dispatch('fetchConfig'),
      p: this.config ? '50%' : '100%',
    }))();
  },
  computed: {
    ...mapState({
      config: state => state.app.config,
    }),
    ...mapGetters([
      'isLogined', 'unpaidOrderCount', 'unpickupOrderCount', 'currentUser',
    ]),
    showTabbar() {
      // 如页面不需要显示底部tabbar，把routeNmae写在这个数组
      return !~[
        'order',
        'order-detail',
        'work-order-detail',
      ].indexOf(this.$route.name);
    },
  },
};
</script>

<style lang="scss">
  @import "style/app";
  @import "style/variables";
  body {
    background-color: #f7f8fa;
  }
  .not-in-wechat {
    background-color: #ffffff;
    text-align: center;
    img {
      width: 80%;
    }
  }
  #app {
    padding-bottom: 50px;
  }
  .orders-menu {
    a {
      color: $text-color;
    }
    .van-badge--fixed {
      right: -6px;
    }
  }
  .api-error-notify {
  }

  .order-status {
    &-order_canceled {
      color: $red;
      .van-cell__value {
        color: $red;
      }
    }
    &-order_closed {
      color: $gray-8;
      .van-cell__value {
        color: $gray-8;
      }
    }
    &-order_paid {
      color: $green;
      .van-cell__value {
        color: $green;
      }
    }
    &-order_tracking {
      color: $green;
      .van-cell__value {
        color: $green;
      }
    }
    &-order_completion {
      color: $blue;
      .van-cell__value {
        color: $blue;
      }
    }
    &-order_created {
      color: $orange-dark;
      .van-cell__value {
        color: $orange-dark;
      }
    }
  }
</style>
