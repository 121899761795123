import { Api } from '@/api';

const state = {
  senderAddress: [],
  receiverAddress: [],
  districtList: [],
  districtFullPathName: [],
  postOfficeList: [],
};
const getters = {};
const actions = {
  fetchDistrict({ commit }) {
    return Api.app.district().then(({ data: response }) => {
      commit('getDistrict', response);
    });
  },
  fetchDistrictFullPathName({ commit }) {
    return Api.app.districtFullPathName().then(({ data: response }) => {
      commit('getDistrictFullPathName', response);
    });
  },
  fetchPostOffice({ commit }) {
    return Api.app.postOffice().then(({ data: response }) => {
      commit('getPostOffice', response);
    });
  },
  fetchSenderAddress({ commit }, params) {
    return Api.address.sender.get(params).then(({ data: response }) => {
      commit('getSenderAddress', response);
    });
  },
  deleteSenderAddress({ commit, state }, id) {
    return Api.address.sender.remove(id).then(() => {
      const arr = state.senderAddress;
      const index = arr.findIndex(item => item.id === id);
      arr.splice(index, 1);
      commit('removeSenderAddress', arr);
    });
  },
  fetchReceiverAddress({ commit }, params) {
    return Api.address.receiver.get(params).then(({ data: response }) => {
      commit('getReceiverAddress', response);
    });
  },
  deleteReceiverAddress({ commit, state }, id) {
    return Api.address.receiver.remove(id).then(() => {
      const arr = state.receiverAddress;
      const index = arr.findIndex(item => item.id === id);
      arr.splice(index, 1);
      commit('removeReceiverAddress', arr);
    });
  },
};
/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  getDistrict(s, data) {
    s.districtList = data;
  },
  getDistrictFullPathName(s, data) {
    s.districtFullPathName = data;
  },
  getPostOffice(s, data) {
    s.postOfficeList = data.data;
  },
  getSenderAddress(s, data) {
    s.senderAddress = data.data;
  },
  removeSenderAddress(s, data) {
    s.senderAddress = data;
  },
  getReceiverAddress(s, data) {
    s.receiverAddress = data.data;
  },
  removeReceiverAddress(s, data) {
    s.receiverAddress = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
