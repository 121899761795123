import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';

import {
  Toast, Dialog, Popup,
  Button,
  Tabbar, TabbarItem,
  Icon,
  Form, Field, Picker, Stepper, RadioGroup, Radio, Switch, CheckboxGroup, Checkbox,
  Cell, CellGroup,
  Sticky,
  Image as VanImage,
  Locale as VanLocale,
  Popover,
  Badge,
  Divider,
  Search,
} from 'vant';
import Van_ZH_CN from 'vant/es/locale/lang/zh-CN';
import Van_ZH_HK from 'vant/es/locale/lang/zh-HK';

import App from './App';
import router from './router';
import store from './store';
import ApiPlugin from './api';
import AppConfig from './app.config';
import { zh_CN, zh_HK } from './i18n';
import './filters';

Vue.use(VueI18n);
Vue.use(ApiPlugin);
Vue.use(VueAxios, axios);

// vant
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Stepper);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Sticky);
Vue.use(VanImage);
Vue.use(Popover);
Vue.use(Badge);
Vue.use(Divider);
Vue.use(Search);

const {
  API_STANDARDS_TREE,
  APP_NAME,
  API_VERSION,
  // SECRET_KEY,
} = AppConfig;

let locale = localStorage.getItem('locale');
if (locale && locale.startsWith('zh_TW')) {
  locale = 'zh_HK';
}
if (!locale) {
  if (window.navigator.language) {
    locale = window.navigator.language.replace('-', '_');
  } else {
    locale = 'zh_HK';
  }
  localStorage.setItem('locale', locale);
}

switch (locale.toLowerCase()) {
  case 'zh_cn':
    locale = 'zh_CN';
    VanLocale.use('zh_CN', Van_ZH_CN);
    break;
  case 'zh_hk':
  default:
    locale = 'zh_HK';
    VanLocale.use('zh_HK', Van_ZH_HK);
    break;
}
const i18n = new VueI18n({
  locale,
  messages: {
    zh_CN, zh_HK,
  },
});

Vue.config.productionTip = process.env.NODE_ENV === 'development';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    // axios Request
    this.axios.defaults.headers.common.Accept = `application/${API_STANDARDS_TREE}.${APP_NAME}.${API_VERSION}+json`;
    this.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    this.axios.interceptors.request.use((config) => {
      /* eslint no-param-reassign: 0 */
      if (this.$store.getters.isLogined) {
        const { token, expiresTime } = this.$store.state.user;
        // 检查token是否过期
        if (expiresTime > Date.now()) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          this.$store.dispatch('logout');
        }
      }
      const l = localStorage.getItem('locale');
      if (locale) {
        config.headers.Locale = l;
      }
      return config;
    });

    this.axios.interceptors.response.use(response => response, (error) => {
      if (error.response) {
        /* eslint default-case:0 */
        switch (error.response.status) {
          case 401:
            return this.$store.dispatch('logout').then(() => {
              window.location.reload();
            });
          case 500:
            //
            break;
        }
      }
      return Promise.reject(error);
    });
  },
}).$mount('#app');
