/* eslint-disable */
export default {
  common: {
    spec_ex: '例：尺碼，容量，型號',
    logining: '正在登入',
    optional: '可選',
    confirmed: '確定',
    cancel: '取消',
    default: '默認',
    delete: '刪除',
    deleted: '已刪除',
    saving: '正在保存中',
    saved: '已保存',
    loading: '加載中',
    submit: '提交',
    edit: '編輯',
    save: '保存',
    other: '其他',
    click_for_select_field: '點擊選擇{field}',
    input_field_placeholder: '請填寫{field}',
    not_more: '沒有更多了',
    wx_not_support_function: '您的微信不支持此功能',
    input_search_keyword: '請輸入搜索關鍵字',
    tips: '提示',
    bind_email_tips: '請先綁定郵箱',
    file_size_max: '文件大小不能超過{size}',
    search: '搜索',
    agree: '同意',
    disagree: '不同意'
  },
  app: {
    home: '首頁',
    create_order: '寄件',
    orders: '查件',
    order_manage: '訂單管理',
    postoffices: '門店',
    address_manage: '地址薄',
    notifications: '消息通知',
    cs: '聯繫客服',
    wordOrder: '工單',
    me: '我',
  },
  address: {
    sender_address: '寄件人',
    receiver_address: '收件人地址',
    create: '新增地址',
    area: '地區',
    zipcode: '郵政編碼',
    card_no: '身份證號碼',
    card_file: '身份證正面照片',
    opposite_file: '身份證背面照片',
    placeholders: {
      area: '點擊選擇城市',
      card_no: '請填入身份證號碼',
      zipcode: '請輸入郵政編碼',
    },
    please_upload_image: '請上傳圖片',
    parse_address_default_placeholder: '在這裡粘貼地址信息，自動拆分姓名、身份證、電話和地址（暫僅支持識別中國大陸地區）',
    parse_address_focus_placeholder: '粘貼或輸入整段信息，點擊"識別"自動拆分地址信息。例如：小智 136****0000 xx省xx市xx區某某街道某大廈001號（暫僅支持識別中國大陸地區）',
    parsed_tips: '如信息不准確，可以繼續編輯修改再提交哦',
    parse_address: '識別',
    parse_clear: '清空',
    phone_area_code: '區號',
    other_phone_area_code: '其他（請輸入带區號的電話）',
    delete_comfirm: '確定要删除這個地址嗎？',
    validate_messages: {
      name: '請輸入真實姓名',
      address: '請輸入詳細地址',
      phone: '請輸入電話號碼',
      card_file: '請上傳身份證正面照片',
      opposite_file: '請上傳身份證背面照片',
      district: '請选择地區',
      zipcode: '請輸入郵政編碼',
      card_no: '請輸入身份證號嗎',
      phone_error: '請輸入正确的電話號碼',
      realname_error: '請輸入真實姓名',
      address_detail: '請輸入詳細地址',
    },
    share_wx_address: '共享微信地址',
    supplement_card: '請補充身份證信息',
  },
  sender_address: {
    name: '姓名',
    phone: '電話',
    isDefault: '設為默認寄件人',
    placeholders: {
      name: '寄件人姓名',
      phone: '寄件人電話號碼',
    },
  },
  receiver_address: {
    name: '姓名',
    phone: '電話',
    address_detail: '詳細地址',
    isDefault: '設為默認收件人',
    placeholders: {
      name: '收件人姓名與身份證必須一致',
      phone: '收件人電話號碼',
      address_detail: '街道門牌、樓層房間號等信息',
    },
  },
  order: {
    copy_tip: '複製成功！請複製到瀏覽器下載發票文件',
    invoice: '發票',
    my_order: '我的運單',
    base_info: '基本信息',
    product_info: '商品信息',
    title: '在線下單',
    reference_id: '訂單號碼',
    parcel_code: '郵包號碼',
    post_office_code: '門店編碼',
    post_office: '寄件門店',
    sender: '寄件人',
    receiver: '收件人',
    upload_small_ticket: '上傳小票',
    small_ticket: '小票',
    input_product_info: '添加商品信息',
    product_limit: '商品種類不能超過{num}個',
    placeholders: {
      parcel_code: '請輸入郵包號碼或掃描條碼',
      parcel_code_or_post_office_code: '請輸入或掃描條碼/二維碼',
    },
    rule_messages: {
      parcel_code_required: '請填寫郵包號碼',
      sender_address_id_required: '請选择寄件人',
      receiver_address_id_required: '請选择收件人',
      products_required: '請上报商品信息',
      ticket_required: '請上傳小票',
    },
    price: '價格',
    parcel_price: '郵包價格',
    tax: '税金',
    additional_fee: '附加費',
    additional_fee_unit: '{num}筆',
    additional_fee_unpaid_tips: '您有{num}筆附加費尚未支付，請盡快支付。',
    additional_fee_unpaid_order_tips: '您有{num}筆訂單尚未支付附加費，請盡快支付。',
    ordered: '下單成功',
    view_tracking: '查看物流',
    tracking_info: '物流信息',
    cancel: '取消',
    cancel_comfirm: '您要取消這個運單嗎？',
    order_canceled: '運單已取消',
    status: '狀態',
    statuses: {
      all: '所有訂單',
      unpaid: '待支付訂單',
      unpickup: '待攬收訂單',
      pickup: '已攬收訂單',
      tracking: '運輸中訂單',
      completion: '已完成訂單',
      close: '已取消訂單',
      issue: '問題訂單',
      additional: '待支付附加費訂單'
    },
    input_parcel_code_to_search: '輸入郵包號碼進行搜索',
    orgin_price: '原價',
    discounted: '已优惠',
    tracking_no: '運單號',
    created_at: '下單時間',
    read_and_agree: '閱讀並同意',
    terms: '《电子運單契約條款》',
    send_tip: '包裹寄出前，收/寄方需清楚明白有義務配合海關申報要求，包括但不限於提供身份證號碼及身份證圖片',
    type: '寄件方式',
    types: {
      1: '門店寄件',
      2: '上門取件',
    },
    parcel_model: '規格',
    payment_success_tips1: '請把郵包交给店员並出示本頁面條形碼完成揽件',
    payment_success_tips2: "郵包號碼：{code}\n請把郵包號碼寫在郵包上，然後創建上門取件單",
    product_limit_tips: '商品限制提示',
    declare_multiple_products_cannot_exceed: '申報多件商品不能超過CNY¥{price}元',
    declare_single_product_cannot_exceed: '申報單件商品不能超過CNY¥{price}元',
    tax_free_limit_exceed: '已超單票申報免稅額{currency}{price}',
    declare_shoe_cannot_exceed: '申報鞋類不能超過1雙',
    target_district: '目的地',
    parcel_type: '包裹類型',
    parcel_type_spx: '郵包',
    parcel_type_doc: '文件',
    long: '長度',
    width: '寬度',
    height: '高度',
    weight: '重量',
    prev_step: '上一步',
    next_step: '下一步',
    confirm_order: '確認下單',
    prices: {
      base: '基本運費',
      peak_season: '旺季附加費',
      fuel_surcharge: '燃油附加費',
      disposal: '排倉費',
      weight_overload: '超重附加費',
      size_overload: '超長附加費',
      remote: '偏遠附加費',
      residential: '住宅附加費',
      tax: '稅金',
    },
    step_0: '報價',
    step_1: '填資料',
    step_2: '下單',
    expected_post_date: '預計投遞時間',
    expected_post_date_popup_title: '選擇預計投遞日期',
    price_detail: '價格詳細',
    load_cache_confirm: '是否讀取您上次未完成的訂單數據',
    district_filter: '輸入地區關鍵字進行篩選',
  },
  pickup_order: {
    id: '取件單號',
    pickup: '上門取件',
    pickup_order: '上門取件單',
    order: '取件訂單',
    order_list: '取件訂單列表',
    no_orders_tips: '您没有待攬收訂單',
    go_to_order: '去下單',
    pickup_address: '取件地址',
    contact: '聯繫人',
    phone: '電話',
    select_pickup_address: '請選擇取件地址',
    address_disabled_text: '非香港地區或没有詳細地址不可用',
    select_order: '寄送訂單',
    select_time: '期望上門時間',
    submit: '提交取件單',
    price: '上門費用',
    free: '免費',
    charges: '上門收費標準：{num}單内，每次{price}。10單或以上，免收上門費。',
    contai_order: '包含訂單',
    status: '狀態',
    statuses: {
      0: '未支付',
      1: '已支付',
      2: '取件中',
      3: '已取件',
      9: '已取消',
    },
    cancel_comfirm: '您确定要取消這個取件單嗎？',
    order_canceled: '取件單已取消',
    pickup_date: '取件日期',
    pickup_date_placeholder: '請選擇上門取件日期',
    pickup_time: '取件時間',
    pickup_time_placeholder: '請選擇上門取件時間段',
    remark: '備註',
    picking_tip: '如需取消请聯繫智郵易客服',
  },
  order_status: {
    order_closed: '已關閉',
    order_created: '待支付',
    order_paid: '已付款',
    order_pickup: '已攬件',
    order_tracking: '運輸中',
    order_completion: '已完成',
    order_canceled: '已取消',
    order_timeout: '超時取消',
    undefined: '未定義',
  },
  order_detail: {
    title: '運單詳情',
    additional_fee: {
      price: '金額',
      reason: '原因',
      payment_status: '支付狀態',
    },
  },
  parcel_model: {
    price: '價格',
    max_weight: '最大重量',
  },
  order_list: {
    title: '我的運單',
  },
  post_offices: {
    title: '門店列表',
  },
  parcel: {
    name: '型號',
    max_weight: '最大重量',
  },
  product: {
    name: '商品名稱',
    en_name: '商品英文名稱',
    quantity: '數量',
    category: '商品分類',
    gross_weight: '重量',
    unit: '單位',
    price: '單價',
    tax: '税金',
    currency: '貨幣',
    post_tax_num: '行郵稅號',
    origin_country: '原產地',
    brand: '品牌',
    spec: '規格',
    origin_country_info: '請選擇原產地',
    rule_messages: {
      name_required: '請填寫商品名稱',
      en_name_required: '請填寫商品英文名稱',
      brand_required: '請填寫商品品牌',
      spec_required: '請填寫商品規格',
      category_required: '請选择商品分類',
      price_required: '請填寫商品價格',
    },
    placeholder: {
      price: '申報價格會以單價乘以數量',
    },
    confirm_delete: '要删除這個商品嗎？',
    tips: '請務必如實申報商品資料！',
    tips2: '如有購買單據，請直接放置於包裹內！',
  },
  payment: {
    wechatpay: '微信支付',
    payment_success: '支付成功',
    payment_fail: '支付失败',
    payment_cancel: '已取消支付',
    status: {
      0: '未支付',
      1: '已支付',
    },
    refunding: '退款中',
    refunded: '已退款',
  },
  coupon: {
    coupon: '優惠券',
    no_use_coupon: '不使用優惠券',
    exchanged: '已兌換優惠券',
    use_amount_condition: '訂單满{price}可用',
    no_condition: '无使用門槛',
    new_user_only: '新用户專用',
    disable_reasons: {
      expired: '已過期',
      amount_condition: '運單满{price}可用',
      district: '該地區不在優惠券適用範圍內',
    },
  },
  notification: {
    all_mark_readed: '全部標記为已讀',
    mark_unreaded: '標記未讀',
  },
  user: {
    title: '會員中心',
    logout: '退出登錄',
    share: '推薦我們',
    orders: '運單',
    cs_tel: '客服電話',
    change_language: '切換語言',
  },
  userinfo: {
    tips: '綁定郵箱/手機號，設定密碼可使您的賬號在E-Smartship應用程式中登錄',
    title: '會員信息',
    bind_email: '綁定郵箱',
    update_password: '修改密碼',
    email: '郵箱',
    email_verify_code: '郵箱驗證碼',
    send_verify_code: '發送驗證碼',
    resend: '重新發送',
    old_password: '原密碼',
    new_password: '新密碼',
    new_password_confirmation: '確認新密碼',
    placeholder: {
      email: '請填寫您的E-mail',
      email_verify_code: '請輸入郵箱驗證碼',
      old_password: '請輸入原密碼',
      new_password: '請輸入新密碼',
      new_password_confirmation: '請再次輸入新密碼',
    },
    message: {
      email_validate: '請輸入正確的郵箱格式',
    },
  },
  workOrder: {
    list: '問題跟蹤',
    create: '問題反饋',
    detail: '工單詳細',
    questionId: {
      300: '報關查詢',
      301: '包裹丟失、破損',
      302: '軌跡查詢',
      303: '派送問題',
      304: '其他',
      308: '虛假簽收',
    },
    types: {
      1: '運單',
      2: '其他',
    },
    type: '类型',
    status: '工單狀態',
    title: '標題',
    question: '問題類型',
    description: '描述',
    email: '聯繫郵箱',
    phone: '聯繫電話',
    billNumber: '郵包號碼',
    attachment: '附件',
    upload_attachment: '上傳附件',
    submit: '提交工單',
    placeholder: {
      question: '點擊選擇問題類型',
      billNumber: '點擊選擇郵包',
      phone: '請輸入聯繫電話',
      title: '請輸入標題',
      description: '請填寫工單描述',
    },
    validate_error: '請正確填寫工單',
    statuses: {
      A0: '待審核',
      A1: '待受理',
      A2: '受理中',
      A3: '受理完成',
      A4: '已關閉',
    },
    refference_no: '工單號',
    info: '工單信息',
    messages: '消息',
    create_success: '您的問題已反饋',
    please_input_message: '請輸入內容',
    send: '發送',
    sending: '發送中',
    view_attachments: '查看附件',
  },
  area: {
    China: '中国大陆',
    HongKong: '香港',
    Macau: '澳門',
    TaiWan: '台湾',
    US: '美国',
    France: '法国',
    UK: '英国',
    Germany: '德国',
    Malaysia: '马来西亚',
    Australia: '澳洲',
    NewZealand: '纽西兰',
    Singapore: '新加坡',
    Thailand: '泰国',
    Japan: '日本',
    Korea: '韩国',
    VietNam: '越南',
    Cambodia: '柬埔寨',
  },
  errors: {
    parcel_disabled: '包裹已使用',
    address_not_exists: '地址不存在',
    parameter_error: '参數錯誤',
    operation_failed: '操作失敗',
    order_not_exist: '訂單不存在',
    invalid_parcel_code: '無效的郵包號嗎',
    order_has_been_paid: '訂單已支付',
    order_has_been_canceled: '訂單已被取消',
    coupon_use_limit: '優惠碼超过限制',
    coupon_expired: '優惠碼過期',
  },
  DDP: {
    tips: '請在「關郵e通」上完成：',
    step1: '1) 規範申報',
    step2: '2) 自行交稅',
    wechat: '微信入口',
    web: '網站入口',
  },
}
