<template>
  <div id="home">
    <van-swipe class="home-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <van-image src="/static/banners/banner-1.jpeg" />
      </van-swipe-item>
    </van-swipe>

    <van-notice-bar
        v-if="unpaidAdditionalFeeCount"
        mode="link"
        @click="$router.push({ name: 'orders', params: { status: 'additional' } })"
        :text="$t('order.additional_fee_unpaid_order_tips', { num: unpaidAdditionalFeeCount })"
    />
    <van-grid class="mt-3 p-3" :column-num="2">
      <van-grid-item icon="logistics" :text="$t('app.create_order')" to="/order" />
      <van-grid-item icon="search" :text="$t('app.orders')" to="/orders" />
      <van-grid-item icon="free-postage" :text="$t('app.postoffices')" to="/post-offices" />
      <van-grid-item icon="records" :text="$t('app.address_manage')" to="/address" />
      <van-grid-item icon="envelop-o" :text="$t('app.notifications')" to="/notifications" :badge="unReadNotifications" />
<!--      <van-grid-item icon="manager-o" :text="$t('app.cs')" @click="cs" />-->
      <van-grid-item icon="comment-o" :text="$t('app.wordOrder')" to="/work-orders" />
    </van-grid>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  Swipe, SwipeItem, Grid, GridItem, NoticeBar,
} from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(NoticeBar);

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['currentUser', 'readedNotificationsCount', 'isLogined', 'unpaidAdditionalFeeCount']),
  },
  data() {
    return {
      unReadNotifications: null,
    };
  },
  mounted() {
    if (this.isLogined) {
      this.$api.app.notifications({ perpage: 999 }).then(({ data: response }) => {
        this.unReadNotifications = response.meta.pagination.total - this.readedNotificationsCount;
        if (this.unReadNotifications <= 0) {
          this.unReadNotifications = null;
        }
      });
    }
  },
  methods: {
    commingsoon() {
      this.$toast('Comming soon!');
    },
    cs() {
      window.location.href = 'tel:+85236931234';
      // this.$api.app.cs().then(() => {
      //   wx.closeWindow();
      // });
    },
  },
};
</script>

<style lang="scss" >
  @import "../style/variables";
  .home-swipe {
  }
</style>
