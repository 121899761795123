import _ from 'lodash';
import { Api } from '@/api';

const state = {
  config: null,
  productCategories: [],
  originCountries: [],
  phoneAreaCodes: [
    {
      code: '+852',
      countryCode: 'HK',
    },
    {
      code: '+86',
      countryCode: 'CN',
    },
    {
      code: '+853',
      countryCode: 'MO',
    },
  ],
  notificationsReaded: [],
  terms: null,
  UserAgreement: null,
  parcelModels: [],
};

const getters = {
  readedNotificationsCount: state => state.notificationsReaded.length,
  pickupParcelModels: state => _.filter(state.parcelModels, { type: 2 }),
  postOfficeParcelModels: state => _.filter(state.parcelModels, { type: 1 }),
};

const actions = {
  fetchOriginCountries({ commit }) {
    return Api.app.originCountry().then(({ data: response }) => {
      commit('receiveOriginCountries', response);
    });
  },
  fetchProductCategories({ commit }) {
    return Api.app.productCategories().then(({ data: response }) => {
      commit('receiveProductCategories', response);
    });
  },
  fetchTerms({ commit }) {
    return Api.app.terms().then(({ data: response }) => {
      commit('receiveTerms', response.data);
    });
  },
  fetchUserAgreement({ commit }) {
    return Api.app.userAgreement().then(({ data: response }) => {
      commit('receiveUserAgreement', response.data);
    });
  },
  fetchParcelModel({ commit }) {
    return Api.app.parcelModel().then(({ data: response }) => {
      commit('receiveParcelModel', response.data);
    });
  },
  fetchConfig({ commit }) {
    return Api.app.config().then(({ data: response }) => {
      commit('receiveConfig', response);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveProductCategories(s, data) {
    s.productCategories = data;
  },
  receiveOriginCountries(s, data) {
    s.originCountries = data;
  },
  readNotification(s, id) {
    if (!~s.notificationsReaded.indexOf(id)) {
      s.notificationsReaded.push(id);
    }
  },
  unreadNotification(s, id) {
    _.pull(s.notificationsReaded, id);
  },
  receiveTerms(s, data) {
    s.terms = data;
  },
  receiveUserAgreement(s, data) {
    s.userAgreement = data;
  },
  receiveParcelModel(s, data) {
    s.parcelModels = data;
  },
  receiveConfig(s, data) {
    s.config = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
