import { Api } from '@/api';

const state = {
  token: '',
  identity: null,
  expiresTime: null,
  myCoupons: [],
  sendEmailVerifyCodeLockTime: null,
};

const getters = {
  isLogined: s => s.token !== '' || s.identity !== null,
  currentUser: s => s.identity,
};

const actions = {
  authorization({ commit }, code) {
    return Api.user.authorization(code).then(({ data: response }) => {
      commit('receiveUserToken', response);
    });
  },
  identity({ commit }) {
    return Api.user.identity().then(({ data: response }) => {
      commit('receiveUserInfo', response.data);
    });
  },
  logout({ commit }) {
    commit('removeToken');
    commit('setExpireTime', null);
  },
  fetchMyCoupons({ commit }) {
    return Api.coupon.myCoupons().then(({ data: response }) => {
      commit('receiveMyCoupons', response.data);
    });
  },
  exchangeCoupon({ commit }, code) {
    return Api.coupon.exchange(code).then(({ data: response }) => {
      commit('receiveMyCoupons', response.data);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveUserInfo(s, response) {
    s.identity = response;
  },
  receiveUserToken(s, response) {
    const expiresTime = Date.now() + (response.expires_in * 1000);
    s.token = response.access_token;
    s.expiresTime = expiresTime;
  },
  removeToken(s) {
    s.token = '';
    s.identity = null;
  },
  setExpireTime(s, expireTime) {
    s.expiresTime = expireTime;
  },
  receiveMyCoupons(s, data) {
    s.myCoupons = [...data];
  },
  receiveCoupon(s, data) {
    s.myCoupons = [data, ...s.myCoupons];
  },
  setSendEmailVerifyCodeLockTime(s, time) {
    s.sendEmailVerifyCodeLockTime = time;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
