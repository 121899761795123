import _ from 'lodash';
import xxtea from 'xxtea-node';
import accounting from 'accounting';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { Notify } from 'vant';
import appConfig from './app.config';

export function Probability(...$args) {
  const probas = [];
  const functions = [];
  const args = _.isArray($args[0]) ? $args[0] : $args;

  args.push({
    p: 0,
    f() {},
  });

  for (let i = 0, l = args.length, sum = 0; i < l; i++) {
    let p = Math.abs(parseFloat(args[i].p));
    const { f } = args[i];

    if (_.isNaN(p) || typeof f !== 'function') {
      throw new TypeError(`Probability.js: Invalid probability object in argument ${i} .`);
    }

    if (/%/.test(args[i].p)) {
      p /= 100.0;
    }

    sum += p;

    if (sum > 1.0) {
      throw new TypeError(`Probability.js: Probability exceeds "1.0"
        (=100%) in argument ${i}: p="${p}" (=${p * 100}%), sum="${sum}" (=${sum * 100}%).`);
    }

    probas[i] = sum;
    functions[i] = f;
  }

  return function probabilitilized() {
    const random = Math.random();
    let i = 0;
    for (let l = probas.length - 1; i < l && random >= probas[i]; i++) {
      /* intentionally left empty */
    }
    return functions[i].apply(this, ...args);
  };
}

export function showErrorMessage(error) {
  const { response } = error;
  if (response) {
    if (response.status === 422 && response.data.errors) {
      let errors = [];
      _.mapValues(response.data.errors, (items) => {
        errors = [...errors, ...items];
      });
      Notify({
        className: 'api-error-notify',
        message: errors.join('\n'),
      });
    } else {
      Notify({
        className: 'api-error-notify',
        message: response.data.message,
      });
    }
  }
}

export function phoneValidator(number, countryCode) {
  const phoneNumber = parsePhoneNumberFromString(number, countryCode);
  if (phoneNumber) {
    return phoneNumber.isValid();
  }
  return false;
}

export function realNameValidator(name) {
  /* eslint-disable no-param-reassign */
  name = name.trim();
  if (name.length < 2) {
    return false;
  }
  const regexps = [
    // /^([\u4e00-\u9fa5]+|([a-z]+\s?)+)$/i,
    /^阿/,
    /先.*生/,
    /女.*士/,
    /小.*姐/,
    /太.*太/,
    /老.*[師师闆板]/,
    /[師师].*[傅父]/,
    /^mr/i,
    /^mrs/i,
    /^miss/i,
    /\d/,
  ];
  for (let i = 0; i < regexps.length; i++) {
    if (regexps[i].test(name)) {
      return false;
    }
  }
  return true;
}

export function formatMoney(number, currency) {
  let symbol;
  let precision = 2;
  let format = '%s%v';
  if (typeof currency === 'string') {
    // eslint-disable-next-line
    currency = currency.toUpperCase();
  }
  switch (currency) {
    case 'EUR':
      symbol = '€';
      break;
    case 'GBP':
      symbol = '£';
      break;
    case 'JPY':
      symbol = '¥';
      precision = 0;
      format = '%s%v円';
      break;
    case 'CNY':
    case 'RMB':
      symbol = 'RMB¥';
      break;
    case 'HKD':
      symbol = 'HK$';
      break;
    case 'USD':
      symbol = 'US$';
      break;
    default:
      symbol = currency;
      break;
  }
  return accounting.formatMoney(number, symbol, precision, ',', '.', format);
}

export function encrypt(data) {
  const s = _.isObject(data) ? JSON.stringify(data) : data;
  return xxtea.encryptToString(s, appConfig.SECRET_KEY);
}

export function decrypt(data) {
  const decrypted = xxtea.decryptToString(data, appConfig.SECRET_KEY);
  try {
    return JSON.parse(decrypted);
  } catch (e) {
    return decrypted;
  }
}

export function urlIsImage(url) {
  return /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i.test(url);
}
