import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/order-index',
    name: 'order-index',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderIndex.vue'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/V2/CreateOrder.vue'),
  },
  {
    path: '/orders/:status?',
    name: 'orders',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderList.vue'),
  },
  {
    path: '/order/:id',
    name: 'order-detail',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderDetail.vue'),
  },
  {
    path: '/pickup-order',
    name: 'pickup-order',
    component: () => import(/* webpackChunkName: "pickup-order" */ '../views/pickupOrder/PickupOrder.vue'),
  },
  {
    path: '/pickup-order/:id',
    name: 'pickup-order-detail',
    component: () => import(/* webpackChunkName: "pickup-order" */ '../views/pickupOrder/PickupOrderDetail.vue'),
  },
  {
    path: '/pickup-orders',
    name: 'pickup-orders',
    component: () => import(/* webpackChunkName: "pickup-order" */ '../views/pickupOrder/PickupOrderList.vue'),
  },
  {
    path: '/address',
    name: 'address',
    component: () => import(/* webpackChunkName: "address" */ '../views/address/Address.vue'),
  },
  {
    path: '/post-offices',
    name: 'post-offices',
    component: () => import(/* webpackChunkName: "post-offices" */ '../views/PostOffices.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notification.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserInfo.vue'),
  },
  {
    path: '/work-orders',
    name: 'work-orders',
    component: () => import(/* webpackChunkName: "work-order" */ '../views/workOrder/WorkOrderList.vue'),
  },
  {
    path: '/work-order',
    name: 'create-work-order',
    component: () => import(/* webpackChunkName: "work-order" */ '../views/workOrder/CreateWorkOrder.vue'),
  },
  {
    path: '/work-order/:id',
    name: 'work-order-detail',
    component: () => import(/* webpackChunkName: "work-order" */ '../views/workOrder/WordOrderDetail.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(async (to) => {
  /* eslint-disable */
  const baseLocation = window.location.origin;
  if (window.__wxjs_is_wkwebview) { // 通过此属性判断IOS
    if (window.entryUrl === '' || window.entryUrl === undefined) {
      window.entryUrl = baseLocation + encodeURIComponent(to.fullPath);
    }
  } else { // 安卓
    window.entryUrl = baseLocation + to.fullPath;
  }
  sessionStorage.setItem('url', window.entryUrl);
  /* eslint-enable */
});

export default router;
